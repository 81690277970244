import React, { Component } from "react"
import LocalNavigation from "../../components/LocalNavigation"
import Sticky2colmun from "../../components/Sticky2column"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import Seo from "../../components/Seo"
import css from "./stylesheets/what-is-kaigo.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"

class PageBasicInformation extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    this.ref = {}

    return (
      <div>
        <Seo title={`WHAT is KAIGO?`} />

        <LayoutBasic
          hasLocalNavi={true}
          reference={this.ref}
          lang={`ja`}
          style={{ marginBottom: `0` }}
        >
          <LocalNavigation
            links={[
              { title: `はじめに`, link: `/ja/what-is-kaigo/#section-1` },
              { title: `KAIGOとは`, link: `/ja/what-is-kaigo/#section-2` },
              {
                title: `わたしたちについて`,
                link: `/ja/what-is-kaigo/#section-3`,
              },
            ]}
          />
          <CenterContentLayout>
            <BasicText>
              <h1 className={css.pageTitle}>WHAT is KAIGO?</h1>
            </BasicText>

            <Sticky2colmun id={`section-1`}>
              {/* column left*/}
              <div>
                <h2>はじめに</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  こんにちは！
                  <br />
                  『KAIGO in
                  JAPAN』は、日本で介護の仕事をしようと考えている人のためのウェブサイトです。介護の仕事や日本での生活について、みなさんのさまざまな疑問に答え、先輩たちのリアルな声を届けたいと思っています。
                  <br />
                  日本で働いている先輩たちのインタビュー、日本と介護についての基本情報、よくある質問などがあります。
                  <br />
                  「やさしい日本語（Easy
                  Japanese）」「日本語」「英語」のなかから言語を選んで、ぜひ読んでみてください。
                </p>
                <p>
                  やさしい日本語監修｜
                  <a
                    href="https://slow-communication.jp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    スローコミュニケーション
                  </a>
                </p>
                <p className={`commonButton`}>
                  <button onClick={this.doClickLangButton}>
                    <div>
                      <span>LANGUAGE</span>
                    </div>
                  </button>
                </p>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-2`}>
              {/* column left*/}
              <div>
                <h2>KAIGOとは</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  介護とは、高齢だったり、心や体に障害があったりして日常生活をおくることが難しい人に対して、その人の状況にあわせた支援を行うことです。具体的には、食事、入浴、排せつ、身の回りの世話などをすること。生活のなかの一つひとつの小さなことを観察し、毎日の生活を整えていきます。
                  <br />
                  日本では、介護を必要としている人は、公的な制度を使って、介護を受けることができます。こうした仕組みがあることで、介護をしてくれる家族などの身近な人がいなかったとしても、一人ひとりの「生きる」ことが保障されます。そして、高齢者や障害者自身が、社会に参加する機会が増えていきます。
                  <br />
                  また、介護サービスは、高齢者や障害者だけでなく、家族にとっても心強い存在です。家族の介護を理由に、働くことや自分の時間をもつことを諦めなくて済みます。介護は、社会にとって重要な仕事です。
                  <br />
                  しかし日本では、人口減少や高齢者の急速な増加によって、介護を担う人が不足しているという課題があります。
                </p>

                <p>
                  介護は、科学的に人間を理解することと、創意工夫が求められる専門的な職業です。
                  <br />
                  大切な視点は、次の３つです。
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>1.</i>
                  <div>高齢者や障害者の毎日の生活を整える</div>
                </h3>
                <p>
                  介護の基本は、生活を整えることです。新鮮な空気、太陽の光、掃除、洗濯、食事、入浴、着替え、排せつ、寝るところなどを整えます。こうした行為が、生きることを支えています。生活のなかの一つひとつの小さなことを観察し、最適な整え方を考えて、実践していきます。
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>2.</i>
                  <div>
                    その人がもっている力を使って、
                    <br className={`only-pc`} />
                    自立に向けて支援する
                  </div>
                </h3>
                <p>
                  たとえ時間がかかっても、自分でトイレに行ける人には自分でトイレに行ってもらい、自分で食器を洗える人には食器を洗ってもらいます。できることは自分でやってもらい、できないことを支援します。その人は何ができるのかを見極める、観察力が重要です。
                </p>

                <h3 className={css.specialHeading}>
                  <i className={css.specialHeadingPrefix}>3.</i>
                  <div>「社会のなかで生きる人間」として支援する</div>
                </h3>
                <p>
                  家族や近所の人との関係を整えていくのも介護の仕事です。買い物に出かけるなど、近所の人と会話を楽しむのも大切なことです。
                  <br />
                  また、終末期をどのように迎えたいか、高齢者本人と家族と介護者が一緒に考えたりもします。本人や家族の希望にあわせて、お墓参りやお祈りに一緒に行くこともあります。
                </p>

                <h3 className={css.Heading3h}>
                  介護施設の1日 <small>(3:47)</small>
                </h3>

                <div className={`fixedRatioBox is-16x9`}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/i8hNvS-DbTk"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="介護施設の1日"
                  ></iframe>
                </div>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-3`}>
              {/* column left*/}
              <div>
                <h2>わたしたちについて</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <h3 className={css.specialHeading}>
                  福祉をツールとして、コンヴィヴィアルな社会をつくる
                </h3>
                <p>
                  わたしたちFUKUSHI FOR
                  CONVIVIALITYは、子ども、障害者、高齢者など誰もが、ありのままにその人らしく、地域で暮らすことができる楽しい共生社会を実現するために、さまざまなソーシャルアクションを展開しています。
                </p>
                <dl>
                  <dt>法人名:</dt>
                  <dd>一般社団法人FUKUSHI FOR CONVIVIALITY</dd>
                  <dt>
                    代表名:
                    <br />
                    <small>(共同代表)</small>
                  </dt>
                  <dd>
                    馬場拓也（社会福祉法人 愛川舜寿会 常務理事）
                    <br />
                    飯田大輔（社会福祉法人 福祉楽団 理事長）
                  </dd>
                  <dt>所在地:</dt>
                  <dd>
                    〒261-7112　千葉県千葉市美浜区中瀬2-6-1
                    WBGマリブイースト12階
                  </dd>
                  <dt>メールアドレス:</dt>
                  <dd>
                    <a href="mailto:contact@f4c.jp" className={`linkUnderline`}>
                      contact@f4c.jp
                    </a>
                  </dd>
                  <dt>ウェブサイト:</dt>
                  <dd>
                    <a
                      href={`https://f4c.jp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://f4c.jp
                    </a>
                  </dd>
                </dl>
              </div>
              {/* /column right*/}
            </Sticky2colmun>
          </CenterContentLayout>
        </LayoutBasic>
      </div>
    )
  }
}
export default PageBasicInformation
